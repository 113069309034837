import DataLoader from 'dataloader'
import { GraphQLClient } from 'graphql-request'
import { createContext, FunctionComponent, useContext } from 'react'

import type { Maybe, Money, Product } from '@/types/bigcommerce'

declare const process: {
	env: {
		NEXT_PUBLIC_BACKEND_API_URL: string | undefined,
		NEXT_PUBLIC_BIGCOMMERCE_STOREFRONT_URL: string | undefined,
		NEXT_PUBLIC_BIGCOMMERCE_STOREFRONT_TOKEN: string | undefined,
	},
}

interface Dependencies {
	apiFetcher: <T>(...args: Parameters<typeof fetch>) => Promise<T>,
	bigcommerceFetcher: GraphQLClient['request'],
	priceFetcher: typeof loader,
}

const DependencyContext = createContext<Dependencies | undefined>(undefined)
DependencyContext.displayName = 'DependencyContext'

const client = new GraphQLClient('/api/graphql')
const loader = new DataLoader<{
	id: number,
	price: number | null,
	sale_price: number | null,
}, {
	price: number | null,
	sale_price: number | null,
}>(async keys => {
	const response = await client.request<{
		site: {
			products: {
				edges?: Array<{
					node: Pick<Product, 'entityId'> & {
						prices?: Maybe<{
							basePrice?: Maybe<Pick<Money, 'value'>>,
							price: Pick<Money, 'value'>,
						}>,
					},
				}>,
			},
		},
	}>(`
		query pricesBySku($ids: [Int!]) {
			site {
				products(entityIds: $ids) {
					edges {
						node {
							entityId
							prices {
								basePrice {
									value
								}
								price {
									value
								}
							}
						}
					}
				}
			}
		}
	`, {
		ids: keys.map(({ id }) => id),
	})

	return keys.map(key => {
		const data = response.site.products.edges?.find(edge => edge.node.entityId === key.id)

		if (data === undefined) {
			return key
		}

		const basePrice = data.node.prices?.basePrice?.value ?? null
		const price = data.node.prices?.price.value ?? null

		return {
			price: basePrice ?? price,
			sale_price: basePrice === price ? null : price,
		}
	})
}, {
	batchScheduleFn: callback => setTimeout(callback, 25),
	maxBatchSize: 9,
})

export const DependencyProvider: FunctionComponent = ({ children }) => (
	<DependencyContext.Provider value={{
		async apiFetcher<T>(input: Parameters<typeof fetch>[0], init?: RequestInit): Promise<T> {
			const options: RequestInit = {
				...init,

				credentials: 'include',
				mode: 'cors',

				headers: {
					...init?.headers,

					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
			}

			let response: Response | null = null

			if (typeof input === 'string') {
				response = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_API_URL!}${input}`, options)
			} else {
				response = await fetch(input, options)
			}

			if (response.status >= 400) {
				throw new Error()
			}

			return response.json() as Promise<T>
		},

		async bigcommerceFetcher(document, variables?, requestHeaders?) {
			return client.request(document, variables, requestHeaders)
		},

		priceFetcher: loader,
	}}>
		{children}
	</DependencyContext.Provider>
)


export const useDependencies = (): Dependencies => {
	const context = useContext(DependencyContext)

	if (!context) {
		throw new Error('useDependencies must be used within a DependencyProvider')
	}

	return context
}

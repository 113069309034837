import { createContext, FunctionComponent, useCallback, useContext } from 'react'
import useSWR, { mutate } from 'swr'

import { useDependencies } from '@/components/providers/DependencyProvider'

// TODO: Figure out what the unknowns could be
export interface CartContents {
	id: string,
	customer_id: number,
	channel_id: number,
	email: string,
	currency: {
		code: string,
	},
	tax_included: boolean,
	base_amount: number,
	discount_amount: number,
	cart_amount: number,
	coupons?: Array<unknown> | null,
	line_items: {
		physical_items?: Array<{
			id: string,
			parent_id?: null,
			variant_id: number,
			product_id: number,
			sku: string,
			name: string,
			url: string,
			quantity: number,
			taxable: boolean,
			image_url: string | null,
			discounts?: Array<unknown> | null,
			coupons?: Array<unknown> | null,
			discount_amount: number,
			coupon_amount: number,
			list_price: number,
			sale_price: number,
			extended_list_price: number,
			extended_sale_price: number,
			is_require_shipping: boolean,
			is_mutable: boolean,
		}> | null,
		digital_items?: Array<unknown> | null,
		gift_certificates?: Array<unknown> | null,
		custom_items?: Array<unknown> | null,
	},
	created_time: string,
	updated_time: string,
	locale: string,
}

interface Product {
	id: number,
}

interface Cart {
	contents?: CartContents | null,
	cartValidating: boolean,

	addToCart: (product: Product, quantity: number) => Promise<void>,
	removeFromCart: (lineId: string) => Promise<void>,
	updateQuantity: (lineId: string, product: Product, quantity: number) => Promise<void>,
}

const CartContext = createContext<Cart | undefined>(undefined)
CartContext.displayName = 'CartContext'

export const CartProvider: FunctionComponent = ({ children }) => {
	const { apiFetcher } = useDependencies()
	const { data, isValidating } = useSWR<{ data: CartContents | null }>('/cart', apiFetcher)

	const addToCart: Cart['addToCart'] = useCallback(async ({ id: product_id }, quantity) => {
		await mutate(
			'/cart',
			apiFetcher<{ data: CartContents | null }>('/cart', {
				method: 'POST',
				body: JSON.stringify({
					product_id,
					quantity,
				}),
			}),
			false,
		)
	}, [apiFetcher])

	const removeFromCart: Cart['removeFromCart'] = useCallback(async lineId => {
		await mutate(
			'/cart',
			apiFetcher<{ data: CartContents | null }>('/cart', {
				method: 'DELETE',
				body: JSON.stringify({
					line_id: lineId,
				}),
			}),
			false,
		)
	}, [apiFetcher])

	const updateQuantity: Cart['updateQuantity'] = useCallback(async (lineId, { id: product_id }, quantity) => {
		if (quantity <= 0) {
			return removeFromCart(lineId)
		}

		await mutate(
			'/cart',
			apiFetcher<{ data: CartContents | null }>('/cart', {
				method: 'PUT',
				body: JSON.stringify({
					line_id: lineId,
					quantity,
					product_id,
				}),
			}),
			false,
		)
	}, [apiFetcher, removeFromCart])

	return (
		<CartContext.Provider value={{
			contents: data?.data,
			cartValidating: isValidating,

			addToCart,
			removeFromCart,
			updateQuantity,
		}}>
			{children}
		</CartContext.Provider>
	)
}

export const useCart = (): Cart => {
	const context = useContext(CartContext)

	if (!context) {
		throw new Error('useCart must be used within a CartProvider')
	}

	return context
}

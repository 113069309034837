import { createContext, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'

import { useAuthentication } from './AuthenticationProvider'
import { useDependencies } from './DependencyProvider'

interface Favorites {
	hasFavorites: boolean,
	addFavorite: (product: Pick<Product, 'id'>) => Promise<void>,
	removeFavorite: (product: Pick<Product, 'id'>) => Promise<void>,
	isFavorited: (product: Pick<Product, 'id'>) => boolean | undefined,
	ids: Array<number> | null,
}

interface Response {
	favorites: Array<number>,
}

const FavoritesContext = createContext<Favorites | undefined>(undefined)
FavoritesContext.displayName = 'FavoritesContext'

export const FavoritesProvider: FunctionComponent = ({ children }) => {
	const { email } = useAuthentication()
	const { apiFetcher } = useDependencies()
	const [ids, setIds] = useState<Array<number> | null>(null)

	useEffect(() => {
		if (typeof email === 'string') {
			void apiFetcher<Response>('/favorites', {
				method: 'GET',
			}).then(response => setIds(response.favorites))
		} else {
			setIds(null)
		}
	}, [email])

	const addFavorite: Favorites['addFavorite'] = useCallback(async ({ id }) => {
		setIds(prev => (prev ? [id, ...prev] : null))

		const response = await apiFetcher<Response>(`/favorites/${id}`, {
			method: 'POST',
		})

		setIds(response.favorites)
	}, [apiFetcher, setIds, email])

	const removeFavorite: Favorites['removeFavorite'] = useCallback(async ({ id }) => {
		setIds(prev => (prev ? prev.filter(el => el !== id) : null))


		const response = await apiFetcher<Response>(`/favorites/${id}`, {
			method: 'DELETE',
		})

		setIds(response.favorites)
	}, [apiFetcher, setIds, email])

	const isFavorited: Favorites['isFavorited'] = useCallback(
		({ id }) => ids?.includes(id),
		[ids, email],
	)

	const hasFavorites = ids !== null

	return (
		<FavoritesContext.Provider value={{
			hasFavorites,
			addFavorite,
			removeFavorite,
			isFavorited,
			ids,
		}}>
			{children}
		</FavoritesContext.Provider>
	)
}

export const useFavorites = (): Favorites => {
	const context = useContext(FavoritesContext)

	if (!context) {
		throw new Error('useFavorites must be used within a FavoritesProvider')
	}

	return context
}

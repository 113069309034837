import '@/styles/app.css'
import 'flickity/css/flickity.css'
import '@/styles/flickity.css'

import * as Fathom from 'fathom-client'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import { FunctionComponent } from 'react'
import { useEffectOnce } from 'react-use'

import { AuthenticationProvider } from '@/components/providers/AuthenticationProvider'
import { CartProvider } from '@/components/providers/CartProvider'
import { DependencyProvider } from '@/components/providers/DependencyProvider'
import { FavoritesProvider } from '@/components/providers/FavoritesProvider'
import { combineProviders } from '@/utils/providers'


const Fallback: FunctionComponent = ({ children }) => <>{children}</>

const Providers = combineProviders([
	DependencyProvider,
	AuthenticationProvider,
	CartProvider,
	FavoritesProvider,
])

type Props = AppProps & {
	Component: {
		Layout?: FunctionComponent,
	},
}

export default function App({ Component, pageProps }: Props): JSX.Element {
	const Layout = Component.Layout ?? Fallback
	const router = useRouter()

	useEffectOnce(() => {
		Fathom.load('GKXYXKSU', {
			includedDomains: ['paceintl.com', 'www.paceintl.com'],
		})

		function onRouteChangeComplete() {
			Fathom.trackPageview()
		}
		router.events.on('routeChangeComplete', onRouteChangeComplete)

		return () => {
			router.events.off('routeChangeComplete', onRouteChangeComplete)
		}
	})

	return (
		<Providers>
			<div className="font-sans">
				<Head>
					<link rel="icon" type="image/svg+xml" href="favicon.svg" />
					<link rel="icon" href="favicon.ico" />
					<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
					<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
					<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
					<link rel="manifest" href="/site.webmanifest" />
					<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
					<meta name="msapplication-TileColor" content="#da532c" />
					<meta name="theme-color" content="#ffffff" />
					<link rel="preconnect" href="https://fonts.gstatic.com" />
					<link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap" rel="stylesheet" />

					<style>{`
						body {
							overflow-y: scroll;
						}
					`}</style>

					{process.env.NODE_ENV === 'production' && (
						<>
							<GoogleAnalytics trackPageViews gaMeasurementId="G-F2DSBYT3LX" />

							<script dangerouslySetInnerHTML={{
								__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
									new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
									j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
									'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
								})(window,document,'script','dataLayer','GTM-KG9V7RH');`,
							}} />

							<script dangerouslySetInnerHTML={{
								__html: `(function(h,o,t,j,a,r){
									h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
									h._hjSettings={hjid:712531,hjsv:6};
									a=o.getElementsByTagName('head')[0];
									r=o.createElement('script');r.async=1;
									r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
									a.appendChild(r);
								})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
							}} />
						</>
					)}

					<title key="page-title">Pace International</title>
				</Head>

				<Layout>
					<Component {...pageProps} />
				</Layout>
			</div>
		</Providers>
	)
}

import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import { createContext, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'


interface Authentication {
	email?: string | null,

	login: (email: string, password: string) => Promise<void>,
	logout: () => Promise<void>,
}

const AuthContext = createContext<Authentication | undefined>(undefined)
AuthContext.displayName = 'AuthenticationContext'

export const AuthenticationProvider: FunctionComponent = ({ children }) => {
	const [email, setEmail] = useState<string | null>(null)

	const logout: Authentication['logout'] = useCallback(async () => {
		await fetch('/api/logout')
		cookies.remove('API_TOKEN')
		setEmail(null)
	}, [])

	const login: Authentication['login'] = useCallback(async (email, password) => {
		try {
			await fetch('/api/login', {
				method: 'POST',
				body: JSON.stringify({ email, password }),
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
			}).then(response => {
				if (!response.ok) {
					throw Error(response.statusText)
				}

				return response
			})

			if (cookies.get('API_TOKEN') !== undefined) {
				setEmail(email)
			}
		} catch (err) {
			void logout()

			throw err
		}
	}, [])

	useEffect(() => {
		if (cookies.get('API_TOKEN') !== undefined) {
			if (email === null) {
				const decoded = jwt.decode(cookies.get('API_TOKEN') as string)

				if (decoded !== null && typeof decoded === 'object') {
					if (decoded.id === undefined || decoded.email === undefined || decoded.token === undefined) {
						void logout()

						return
					}

					if (typeof decoded.email === 'string') {
						setEmail(decoded.email)
					}
				}
			}
		}
	}, [email])

	return (
		<AuthContext.Provider value={{
			email,

			login,
			logout,
		}}>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuthentication = (): Authentication => {
	const context = useContext(AuthContext)

	if (context === undefined) {
		throw new Error('useAuthentication must be used within a AuthenticationProvider')
	}

	return context
}
